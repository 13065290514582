/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const HazeNightIcon = () => {
    return (
        <svg
            width={288}
            height={126}
            viewBox="0 0 288 205"
            fill="none"
            className="w-100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M145.908 58.68C145.908 58.68 136.144 71.916 128.513 71.48C118.947 70.918 117.313 59.38 108.445 58.68C99.1669 58.819 98.2199 70.78 88.1639 71.48C79.1539 71.319 75.3369 58.898 66.7479 58.68C58.1589 58.462 49.0039 71.48 49.0039 71.48"
                stroke="white"
                strokeWidth={16}
                strokeLinecap="round"
            />
            <path
                d="M153.919 132.68C153.919 132.68 139.219 145.916 127.726 145.48C113.326 144.918 110.855 133.38 97.508 132.68C83.538 132.819 82.108 144.78 66.969 145.48C53.403 145.317 47.655 132.897 34.719 132.68C21.783 132.463 8 145.48 8 145.48"
                stroke="white"
                strokeWidth={16}
                strokeLinecap="round"
            />
            <path
                d="M235.919 183.68C235.919 183.68 221.219 196.916 209.726 196.48C195.326 195.918 192.855 184.38 179.508 183.68C165.538 183.819 164.108 195.78 148.969 196.48C135.403 196.317 129.655 183.897 116.719 183.68C103.783 183.463 90 196.48 90 196.48"
                stroke="white"
                strokeWidth={16}
                strokeLinecap="round"
            />
            <path
                d="M213.391 13.2819C225.233 13.2661 236.805 16.8188 246.598 23.4769C255.489 29.5042 262.593 37.8151 267.162 47.5363C271.732 57.2575 273.598 68.0301 272.566 78.722C271.534 89.4138 267.641 99.6303 261.296 108.298C254.951 116.965 246.389 123.764 236.509 127.979C225.944 132.454 214.306 133.764 203.011 131.749C191.716 129.734 181.249 124.482 172.883 116.63C161.935 106.379 155.251 92.3793 154.162 77.4209C157.618 78.2309 161.155 78.6393 164.705 78.6379C172.389 78.6342 179.949 76.7019 186.692 73.0179C193.436 69.334 199.146 64.0163 203.301 57.5523C207.456 51.0883 209.921 43.6848 210.472 36.0206C211.023 28.3563 209.641 20.6764 206.453 13.6849C208.756 13.4166 211.072 13.282 213.391 13.2819V13.2819Z"
                fill="#713FFD"
            />
            <g filter="url(#filter0_f_128_241)">
                <path
                    d="M253.582 31.7361C240.25 19.7316 222.784 17.394 214.572 26.515C206.359 35.6359 210.51 52.7614 223.842 64.7659C237.174 76.7704 254.64 79.108 262.853 69.987C271.065 60.8661 266.915 43.7406 253.582 31.7361Z"
                    fill="#A586FC"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_128_241"
                    x="190.355"
                    y="0.951172"
                    width="96.7129"
                    height="94.5996"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur_128_241" />
                </filter>
            </defs>
        </svg>
    );
};

export default HazeNightIcon;

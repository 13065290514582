/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const BrokenCloudsIcon = () => {
    return (
        <svg
            width={470}
            height={118}
            viewBox="0 0 470 302"
            fill="none"
            className="w-100 mt-2"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_127_223)">
                <path
                    d="M283.345 270H91.913C73.3426 269.986 55.4976 262.789 42.1137 249.915C28.7298 237.041 20.8447 219.489 20.1092 200.933C19.3737 182.377 25.8448 164.256 38.1677 150.364C50.4906 136.471 67.71 127.884 86.221 126.4C88.8608 107.76 97.2781 90.4166 110.289 76.8099C123.299 63.2032 140.248 54.0179 158.752 50.5463C177.255 47.0747 196.381 49.4915 213.439 57.4567C230.497 65.4218 244.628 78.5345 253.845 94.9499C270.75 89.0981 289.015 88.4142 306.31 92.9856C323.605 97.557 339.146 107.176 350.952 120.617C362.757 134.057 370.292 150.71 372.594 168.45C374.896 186.19 371.862 204.214 363.879 220.223C359.194 234.688 350.047 247.296 337.751 256.239C325.455 265.182 310.641 270 295.437 270H283.345Z"
                    fill="url(#paint0_linear_127_223)"
                />
                <path
                    d="M295.437 269C303.157 269 310.748 267.767 317.998 265.336C325.009 262.985 331.59 259.554 337.56 255.139C343.462 250.775 348.635 245.547 352.936 239.6C357.29 233.58 360.652 226.957 362.928 219.915L362.951 219.843L362.985 219.775C366.011 213.728 368.341 207.33 369.911 200.761C371.526 194 372.345 187.015 372.345 179.999C372.345 173.96 371.737 167.925 370.537 162.062C369.368 156.348 367.623 150.728 365.352 145.357C363.121 140.084 360.361 134.997 357.146 130.24C353.962 125.527 350.306 121.095 346.278 117.067C342.25 113.039 337.819 109.383 333.106 106.199C328.348 102.984 323.261 100.224 317.988 97.9932C312.617 95.7216 306.997 93.9769 301.283 92.8077C295.42 91.6079 289.385 90.9995 283.345 90.9995C273.346 90.9995 263.531 92.6452 254.172 95.8908L253.383 96.1646L252.973 95.4362C245.357 81.8943 234.279 70.5217 220.937 62.5479C214.189 58.5153 206.94 55.399 199.391 53.2857C191.602 51.1055 183.512 50 175.345 50C164.361 50 153.638 51.977 143.475 55.876C133.657 59.6427 124.65 65.0837 116.705 72.048C108.806 78.9722 102.251 87.1422 97.2213 96.3308C92.0685 105.745 88.7006 115.907 87.2113 126.535L87.0998 127.33L86.299 127.393C77.3408 128.094 68.7219 130.453 60.6818 134.403C52.8694 138.242 45.8932 143.434 39.947 149.834C27.7289 162.987 21 180.123 21 198.087C21 207.66 22.8748 216.947 26.5723 225.689C28.3495 229.891 30.5491 233.943 33.1102 237.734C35.6472 241.489 38.5607 245.02 41.7699 248.23C44.9791 251.439 48.5104 254.352 52.2655 256.889C56.0565 259.45 60.109 261.65 64.3106 263.427C73.0525 267.125 82.3391 269 91.9127 269H283.345H295.437ZM295.437 270H283.345H91.9127C52.1966 270 20 237.803 20 198.087C20 160.286 49.1646 129.297 86.221 126.396C92.3504 82.6574 129.917 49 175.345 49C209.048 49 238.422 67.5232 253.845 94.946C263.087 91.7407 273.012 89.9995 283.345 89.9995C333.051 89.9995 373.345 130.294 373.345 179.999C373.345 194.456 369.936 208.118 363.879 220.223C354.544 249.108 327.431 270 295.437 270Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    d="M339.345 249H147.913C129.343 248.986 111.498 241.789 98.1137 228.915C84.7298 216.041 76.8447 198.489 76.1092 179.933C75.3737 161.377 81.8448 143.256 94.1677 129.364C106.491 115.471 123.71 106.884 142.221 105.4C144.861 86.7599 153.278 69.4166 166.289 55.8099C179.299 42.2032 196.248 33.0179 214.752 29.5463C233.255 26.0747 252.381 28.4915 269.439 36.4567C286.497 44.4218 300.628 57.5345 309.845 73.9499C326.75 68.0981 345.015 67.4142 362.31 71.9856C379.605 76.557 395.146 86.1764 406.952 99.6169C418.757 113.057 426.292 129.71 428.594 147.45C430.896 165.19 427.862 183.214 419.879 199.223C415.194 213.688 406.047 226.296 393.751 235.239C381.455 244.182 366.641 249 351.437 249H339.345Z"
                    fill="url(#paint1_linear_127_223)"
                />
                <path
                    d="M351.437 248C359.157 248 366.748 246.767 373.998 244.336C381.009 241.985 387.59 238.554 393.56 234.139C399.462 229.775 404.635 224.547 408.936 218.6C413.29 212.58 416.652 205.957 418.928 198.915L418.951 198.843L418.985 198.775C422.011 192.728 424.341 186.33 425.911 179.761C427.526 173 428.345 166.015 428.345 158.999C428.345 152.96 427.737 146.925 426.537 141.062C425.368 135.348 423.623 129.728 421.352 124.357C419.121 119.084 416.361 113.997 413.146 109.24C409.962 104.527 406.306 100.095 402.278 96.0672C398.25 92.0395 393.819 88.3828 389.106 85.1988C384.348 81.9845 379.261 79.2237 373.988 76.9932C368.617 74.7216 362.997 72.9769 357.283 71.8077C351.42 70.6079 345.385 69.9995 339.345 69.9995C329.346 69.9995 319.531 71.6452 310.172 74.8908L309.383 75.1646L308.973 74.4362C301.357 60.8943 290.279 49.5217 276.937 41.5479C270.189 37.5153 262.94 34.399 255.391 32.2857C247.602 30.1055 239.512 29 231.345 29C220.361 29 209.638 30.977 199.475 34.876C189.657 38.6427 180.65 44.0837 172.705 51.048C164.806 57.9722 158.251 66.1422 153.221 75.3308C148.069 84.7451 144.701 94.9073 143.211 105.535L143.1 106.33L142.299 106.393C133.341 107.094 124.722 109.453 116.682 113.403C108.869 117.242 101.893 122.434 95.947 128.834C83.7289 141.987 77 159.123 77 177.087C77 186.66 78.8748 195.947 82.5723 204.689C84.3495 208.891 86.5491 212.943 89.1102 216.734C91.6472 220.489 94.5607 224.02 97.7699 227.23C100.979 230.439 104.51 233.352 108.266 235.889C112.056 238.45 116.109 240.65 120.311 242.427C129.052 246.125 138.339 248 147.913 248H339.345H351.437ZM351.437 249H339.345H147.913C108.197 249 76 216.803 76 177.087C76 139.286 105.165 108.297 142.221 105.396C148.35 61.6574 185.917 28 231.345 28C265.048 28 294.422 46.5232 309.845 73.946C319.087 70.7407 329.012 68.9995 339.345 68.9995C389.051 68.9995 429.345 109.294 429.345 158.999C429.345 173.456 425.936 187.118 419.879 199.223C410.544 228.108 383.431 249 351.437 249Z"
                    fill="white"
                    fillOpacity="0.5"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_127_223"
                    x={0}
                    y={0}
                    width="469.346"
                    height={302}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={10} dy={2} />
                    <feGaussianBlur stdDeviation={15} />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.302 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_127_223"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_127_223"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_127_223"
                    x1="196.698"
                    y1="49.0024"
                    x2="299.341"
                    y2="297.166"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset={1} stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_127_223"
                    x1="252.698"
                    y1="28.0024"
                    x2="355.341"
                    y2="276.166"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.764" />
                    <stop offset={1} stopColor="white" stopOpacity="0.35" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BrokenCloudsIcon;

/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const ThunderStormNightIcon = () => {
    return (
        <svg
            width={441}
            height={126}
            className="w-100"
            viewBox="0 0 441 381"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M206.333 208.001H274.405L235.272 265.467L281.027 266.333L184.99 380.001L208.26 293.338H165.66L206.333 208.001Z"
                fill="url(#paint0_linear_114_47)"
            />
            <path
                d="M311.518 268.484H341.405L324.224 293.715L344.313 294.095L302.147 344.001L312.364 305.952H293.664L311.518 268.484Z"
                fill="url(#paint1_linear_114_47)"
            />
            <path
                d="M104.518 294.001H134.405L117.224 319.232L137.313 319.612L95.1471 369.518L105.364 331.469H86.6641L104.518 294.001Z"
                fill="url(#paint2_linear_114_47)"
            />
            <path
                d="M103.268 6.72321C110.278 6.72145 117.27 7.42973 124.137 8.8372C137.39 11.5669 149.974 16.8889 161.164 24.4972C176.667 35.0049 189.053 49.4946 197.021 66.4436C204.989 83.3927 208.244 102.175 206.444 120.817C204.644 139.459 197.856 157.271 186.793 172.383C175.73 187.495 160.801 199.347 143.574 206.695C137.312 209.356 130.8 211.388 124.136 212.76C109.685 215.69 94.7754 215.508 80.4005 212.224C66.0257 208.941 52.5155 202.632 40.77 193.718C29.0244 184.804 19.3132 173.489 12.2832 160.528C5.25319 147.566 1.06578 133.256 -7.62939e-05 118.549C11.3314 121.201 23.1023 121.372 34.5059 119.049C44.7389 116.954 54.4592 112.863 63.1119 107.012C75.9742 98.3289 86.0422 86.1017 92.0959 71.8122C94.1414 66.9727 95.7027 61.9424 96.7569 56.7952C99.004 45.7665 98.9209 34.3899 96.5129 23.3952C95.3016 17.8975 93.5102 12.5438 91.1689 7.42421C95.185 6.95686 99.2248 6.7228 103.268 6.72321V6.72321Z"
                fill="#713FFD"
            />
            <g filter="url(#filter0_f_114_47)">
                <path
                    d="M173.339 38.8962C150.095 17.9667 119.644 13.8915 105.325 29.7939C91.0067 45.6963 98.2426 75.5545 121.487 96.484C144.732 117.413 175.183 121.489 189.501 105.586C203.82 89.6839 196.584 59.8257 173.339 38.8962Z"
                    fill="#A586FC"
                />
            </g>
            <g filter="url(#filter1_d_114_47)">
                <path
                    d="M310.345 282H118.913C100.343 281.986 82.4976 274.789 69.1137 261.915C55.7298 249.041 47.8447 231.489 47.1092 212.933C46.3737 194.377 52.8448 176.256 65.1677 162.364C77.4906 148.471 94.71 139.884 113.221 138.4C115.861 119.76 124.278 102.417 137.289 88.8099C150.299 75.2032 167.248 66.0179 185.752 62.5463C204.255 59.0747 223.381 61.4915 240.439 69.4567C257.497 77.4218 271.628 90.5345 280.845 106.95C297.75 101.098 316.015 100.414 333.311 104.986C350.606 109.558 366.148 119.178 377.953 132.619C389.759 146.06 397.293 162.713 399.594 180.453C401.896 198.194 398.861 216.218 390.877 232.227C386.192 246.69 377.045 259.298 364.749 268.24C352.453 277.182 337.641 281.999 322.437 282H310.345Z"
                    fill="url(#paint3_radial_114_47)"
                    fillOpacity="0.8"
                />
                <path
                    d="M322.437 281C330.156 281 337.746 279.767 344.996 277.336C352.006 274.985 358.587 271.555 364.557 267.14C370.458 262.776 375.631 257.548 379.932 251.602C384.287 245.582 387.649 238.96 389.925 231.919L389.949 231.847L389.983 231.779C393.01 225.731 395.341 219.333 396.911 212.763C398.526 206.003 399.345 199.017 399.345 191.999C399.345 185.96 398.737 179.925 397.537 174.062C396.368 168.348 394.623 162.728 392.352 157.357C390.121 152.084 387.36 146.997 384.146 142.24C380.962 137.527 377.305 133.095 373.278 129.067C369.25 125.039 364.818 121.383 360.105 118.199C355.347 114.984 350.261 112.224 344.988 109.993C339.617 107.722 333.997 105.977 328.283 104.808C322.42 103.608 316.384 103 310.345 103C300.346 103 290.531 104.645 281.172 107.891L280.383 108.165L279.973 107.436C272.357 93.8942 261.279 82.5216 247.937 74.5478C241.189 70.5152 233.94 67.399 226.391 65.2857C218.602 63.1055 210.512 62 202.345 62C191.361 62 180.638 63.977 170.475 67.876C160.657 71.6427 151.65 77.0837 143.705 84.048C135.806 90.9722 129.251 99.1422 124.221 108.331C119.069 117.745 115.701 127.907 114.211 138.535L114.1 139.33L113.299 139.393C104.341 140.094 95.7219 142.453 87.6818 146.403C79.8694 150.242 72.8932 155.434 66.947 161.834C54.7289 174.987 48 192.123 48 210.087C48 219.66 49.8748 228.947 53.5723 237.689C55.3495 241.891 57.5491 245.943 60.1102 249.734C62.6472 253.489 65.5607 257.02 68.7699 260.23C71.9791 263.439 75.5104 266.352 79.2655 268.889C83.0565 271.45 87.109 273.65 91.3106 275.427C100.052 279.125 109.339 281 118.913 281H310.345H322.437ZM322.437 282H310.345H118.913C79.1966 282 47 249.803 47 210.087C47 172.286 76.1646 141.297 113.221 138.396C119.35 94.6574 156.917 61 202.345 61C236.048 61 265.422 79.5231 280.845 106.946C290.087 103.741 300.012 102 310.345 102C360.051 102 400.345 142.294 400.345 191.999C400.345 206.459 396.936 220.121 390.877 232.227C381.54 261.109 354.427 282 322.437 282Z"
                    fill="url(#paint4_linear_114_47)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_114_47"
                    x="77.9736"
                    y="0.0932617"
                    width="138.879"
                    height="135.194"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur_114_47" />
                </filter>
                <filter
                    id="filter1_d_114_47"
                    x={27}
                    y={33}
                    width="413.346"
                    height={281}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={10} dy={2} />
                    <feGaussianBlur stdDeviation={15} />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.302 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_114_47"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_114_47"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_114_47"
                    x1="212.73"
                    y1="294.001"
                    x2="158.841"
                    y2="274.245"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF0AA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_114_47"
                    x1="314.329"
                    y1="306.243"
                    x2="290.67"
                    y2="297.57"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF0AA" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_114_47"
                    x1="107.329"
                    y1="331.76"
                    x2="83.67"
                    y2="323.087"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF0AA" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_114_47"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(330.568 162.109) rotate(43.4125) scale(147.118 159.643)"
                >
                    <stop stopColor="white" stopOpacity="0.604" />
                    <stop offset="0.0001" stopColor="white" stopOpacity="0.95" />
                    <stop offset={1} stopColor="white" stopOpacity="0.95" />
                </radialGradient>
                <linearGradient
                    id="paint4_linear_114_47"
                    x1="118.852"
                    y1="108.06"
                    x2="357.352"
                    y2="282.06"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.1" />
                    <stop offset={1} stopColor="white" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ThunderStormNightIcon;

/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const ShowersNightIcon = () => {
    return (
        <svg
            width={433}
            height={126}
            className="w-100"
            viewBox="0 0 433 378"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M135.979 204.359C136.695 204.377 143.961 229.607 143.961 239.411C143.961 249.215 140.387 257.164 135.979 257.164C131.571 257.164 127.997 249.216 127.997 239.412C127.997 229.608 135.263 204.342 135.979 204.359Z"
                fill="#42A0F0"
            />
            <path
                d="M132.123 242.411C133.289 242.768 134.825 241.125 135.553 238.742C136.282 236.359 135.927 234.138 134.761 233.782C133.595 233.425 132.059 235.068 131.33 237.451C130.602 239.834 130.956 242.055 132.123 242.411Z"
                fill="white"
            />
            <path
                d="M191.37 204.359C192.086 204.377 199.352 229.607 199.352 239.411C199.352 249.215 195.778 257.164 191.37 257.164C186.962 257.164 183.388 249.216 183.388 239.412C183.388 229.608 190.654 204.342 191.37 204.359Z"
                fill="#42A0F0"
            />
            <path
                d="M187.513 242.411C188.679 242.768 190.215 241.125 190.944 238.742C191.672 236.359 191.318 234.138 190.152 233.782C188.985 233.425 187.449 235.068 186.721 237.451C185.992 239.834 186.347 242.055 187.513 242.411Z"
                fill="white"
            />
            <path
                d="M250.797 204.359C251.513 204.377 258.779 229.607 258.779 239.411C258.779 249.215 255.205 257.164 250.797 257.164C246.389 257.164 242.815 249.216 242.815 239.412C242.815 229.608 250.081 204.342 250.797 204.359Z"
                fill="#42A0F0"
            />
            <path
                d="M246.941 242.411C248.107 242.768 249.643 241.125 250.372 238.742C251.1 236.359 250.745 234.138 249.579 233.782C248.413 233.425 246.877 235.068 246.149 237.451C245.42 239.834 245.775 242.055 246.941 242.411Z"
                fill="white"
            />
            <path
                d="M305.684 201.359C306.4 201.377 313.666 226.607 313.666 236.411C313.666 246.215 310.092 254.164 305.684 254.164C301.276 254.164 297.702 246.216 297.702 236.412C297.702 226.608 304.968 201.342 305.684 201.359Z"
                fill="#42A0F0"
            />
            <path
                d="M301.828 239.411C302.994 239.768 304.53 238.125 305.258 235.742C305.987 233.359 305.632 231.138 304.466 230.782C303.3 230.425 301.764 232.068 301.035 234.451C300.307 236.834 300.661 239.055 301.828 239.411Z"
                fill="white"
            />
            <path
                d="M219.336 271.359C220.052 271.377 227.318 296.607 227.318 306.411C227.318 316.215 223.744 324.164 219.336 324.164C214.928 324.164 211.354 316.216 211.354 306.412C211.354 296.608 218.62 271.342 219.336 271.359Z"
                fill="#42A0F0"
            />
            <path
                d="M215.48 309.411C216.646 309.768 218.182 308.125 218.911 305.742C219.639 303.359 219.285 301.138 218.118 300.782C216.952 300.425 215.416 302.068 214.688 304.451C213.959 306.834 214.314 309.055 215.48 309.411Z"
                fill="white"
            />
            <path
                d="M278.762 271.359C279.478 271.377 286.744 296.607 286.744 306.411C286.744 316.215 283.17 324.164 278.762 324.164C274.354 324.164 270.78 316.216 270.78 306.412C270.78 296.608 278.046 271.342 278.762 271.359Z"
                fill="#42A0F0"
            />
            <path
                d="M274.906 309.411C276.072 309.768 277.608 308.125 278.336 305.742C279.065 303.359 278.71 301.138 277.544 300.782C276.378 300.425 274.842 302.068 274.113 304.451C273.385 306.834 273.74 309.055 274.906 309.411Z"
                fill="white"
            />
            <path
                d="M341.684 271.359C342.4 271.377 349.666 296.607 349.666 306.411C349.666 316.215 346.092 324.164 341.684 324.164C337.276 324.164 333.702 316.216 333.702 306.412C333.702 296.608 340.968 271.342 341.684 271.359Z"
                fill="#42A0F0"
            />
            <path
                d="M337.828 309.411C338.994 309.768 340.53 308.125 341.258 305.742C341.987 303.359 341.632 301.138 340.466 300.782C339.3 300.425 337.764 302.068 337.035 304.451C336.307 306.834 336.661 309.055 337.828 309.411Z"
                fill="white"
            />
            <path
                d="M159.91 274.359C160.626 274.377 167.892 299.607 167.892 309.411C167.892 319.215 164.318 327.164 159.91 327.164C155.502 327.164 151.928 319.216 151.928 309.412C151.928 299.608 159.194 274.342 159.91 274.359Z"
                fill="#42A0F0"
            />
            <path
                d="M156.053 312.411C157.219 312.768 158.755 311.125 159.484 308.742C160.213 306.359 159.858 304.138 158.692 303.782C157.525 303.425 155.989 305.068 155.261 307.451C154.532 309.834 154.887 312.055 156.053 312.411Z"
                fill="white"
            />
            <path
                d="M103.979 271.359C104.695 271.377 111.961 296.607 111.961 306.411C111.961 316.215 108.387 324.164 103.979 324.164C99.5711 324.164 95.9971 316.216 95.9971 306.412C95.9971 296.608 103.263 271.342 103.979 271.359Z"
                fill="#42A0F0"
            />
            <path
                d="M100.123 309.411C101.289 309.768 102.825 308.125 103.553 305.742C104.282 303.359 103.927 301.138 102.761 300.782C101.595 300.425 100.059 302.068 99.3302 304.451C98.6017 306.834 98.9564 309.055 100.123 309.411Z"
                fill="white"
            />
            <path
                d="M135.979 324.359C136.695 324.377 143.961 349.607 143.961 359.411C143.961 369.215 140.387 377.164 135.979 377.164C131.571 377.164 127.997 369.216 127.997 359.412C127.997 349.608 135.263 324.342 135.979 324.359Z"
                fill="#42A0F0"
            />
            <path
                d="M132.123 362.411C133.289 362.768 134.825 361.125 135.553 358.742C136.282 356.359 135.927 354.138 134.761 353.782C133.595 353.425 132.059 355.068 131.33 357.451C130.602 359.834 130.956 362.055 132.123 362.411Z"
                fill="white"
            />
            <path
                d="M191.37 324.359C192.086 324.377 199.352 349.607 199.352 359.411C199.352 369.215 195.778 377.164 191.37 377.164C186.962 377.164 183.388 369.216 183.388 359.412C183.388 349.608 190.654 324.342 191.37 324.359Z"
                fill="#42A0F0"
            />
            <path
                d="M187.513 362.411C188.679 362.768 190.215 361.125 190.944 358.742C191.672 356.359 191.318 354.138 190.152 353.782C188.985 353.425 187.449 355.068 186.721 357.451C185.992 359.834 186.347 362.055 187.513 362.411Z"
                fill="white"
            />
            <path
                d="M250.797 324.359C251.513 324.377 258.779 349.607 258.779 359.411C258.779 369.215 255.205 377.164 250.797 377.164C246.389 377.164 242.815 369.216 242.815 359.412C242.815 349.608 250.081 324.342 250.797 324.359Z"
                fill="#42A0F0"
            />
            <path
                d="M246.941 362.411C248.107 362.768 249.643 361.125 250.372 358.742C251.1 356.359 250.745 354.138 249.579 353.782C248.413 353.425 246.877 355.068 246.149 357.451C245.42 359.834 245.775 362.055 246.941 362.411Z"
                fill="white"
            />
            <path
                d="M305.684 324.359C306.4 324.377 313.666 349.607 313.666 359.411C313.666 369.215 310.092 377.164 305.684 377.164C301.276 377.164 297.702 369.216 297.702 359.412C297.702 349.608 304.968 324.342 305.684 324.359Z"
                fill="#42A0F0"
            />
            <path
                d="M301.828 362.411C302.994 362.768 304.53 361.125 305.258 358.742C305.987 356.359 305.632 354.138 304.466 353.782C303.3 353.425 301.764 355.068 301.035 357.451C300.307 359.834 300.661 362.055 301.828 362.411Z"
                fill="white"
            />
            <path
                d="M103.268 6.72324C110.278 6.72148 117.27 7.42976 124.137 8.83723C137.39 11.5669 149.974 16.8889 161.164 24.4972C176.667 35.0049 189.053 49.4947 197.021 66.4437C204.989 83.3927 208.244 102.175 206.444 120.817C204.644 139.459 197.856 157.271 186.793 172.383C175.73 187.495 160.801 199.347 143.574 206.695C137.312 209.356 130.8 211.388 124.136 212.76C109.685 215.69 94.7754 215.508 80.4005 212.224C66.0257 208.941 52.5155 202.632 40.77 193.718C29.0244 184.804 19.3132 173.489 12.2832 160.528C5.25319 147.566 1.06578 133.256 -7.62939e-05 118.549C11.3314 121.201 23.1023 121.372 34.5059 119.049C44.7389 116.954 54.4592 112.863 63.1119 107.012C75.9742 98.329 86.0422 86.1018 92.0959 71.8122C94.1414 66.9727 95.7027 61.9425 96.7569 56.7952C99.004 45.7666 98.9209 34.3899 96.5129 23.3952C95.3016 17.8975 93.5102 12.5439 91.1689 7.42424C95.185 6.95689 99.2248 6.72283 103.268 6.72324V6.72324Z"
                fill="#713FFD"
            />
            <g filter="url(#filter0_f_135_51)">
                <path
                    d="M173.339 38.8962C150.095 17.9667 119.644 13.8915 105.325 29.7939C91.0067 45.6963 98.2426 75.5545 121.487 96.484C144.732 117.413 175.183 121.489 189.501 105.586C203.82 89.6839 196.584 59.8257 173.339 38.8962Z"
                    fill="#A586FC"
                />
            </g>
            <g filter="url(#filter1_d_135_51)">
                <path
                    d="M302.345 282H110.913C92.3426 281.986 74.4976 274.789 61.1137 261.915C47.7298 249.041 39.8447 231.489 39.1092 212.933C38.3737 194.377 44.8448 176.256 57.1677 162.364C69.4906 148.471 86.71 139.884 105.221 138.4C107.861 119.76 116.278 102.417 129.289 88.8099C142.299 75.2032 159.248 66.0179 177.752 62.5463C196.255 59.0747 215.381 61.4915 232.439 69.4567C249.497 77.4218 263.628 90.5345 272.845 106.95C289.75 101.098 308.015 100.414 325.311 104.986C342.606 109.558 358.148 119.178 369.953 132.619C381.759 146.06 389.293 162.713 391.594 180.453C393.896 198.194 390.861 216.218 382.877 232.227C378.192 246.69 369.045 259.298 356.749 268.24C344.453 277.182 329.641 281.999 314.437 282H302.345Z"
                    fill="url(#paint0_radial_135_51)"
                    fillOpacity="0.8"
                />
                <path
                    d="M314.437 281C322.156 281 329.746 279.767 336.996 277.336C344.006 274.985 350.587 271.555 356.557 267.14C362.458 262.776 367.631 257.548 371.932 251.602C376.287 245.582 379.649 238.96 381.925 231.919L381.949 231.847L381.983 231.779C385.01 225.731 387.341 219.333 388.911 212.763C390.526 206.003 391.345 199.017 391.345 191.999C391.345 185.96 390.737 179.925 389.537 174.062C388.368 168.348 386.623 162.728 384.352 157.357C382.121 152.084 379.36 146.997 376.146 142.24C372.962 137.527 369.305 133.095 365.278 129.067C361.25 125.039 356.818 121.383 352.105 118.199C347.347 114.984 342.261 112.224 336.988 109.993C331.617 107.722 325.997 105.977 320.283 104.808C314.42 103.608 308.384 103 302.345 103C292.346 103 282.531 104.645 273.172 107.891L272.383 108.165L271.973 107.436C264.357 93.8942 253.279 82.5216 239.937 74.5478C233.189 70.5152 225.94 67.399 218.391 65.2857C210.602 63.1055 202.512 62 194.345 62C183.361 62 172.638 63.977 162.475 67.876C152.657 71.6427 143.65 77.0837 135.705 84.048C127.806 90.9722 121.251 99.1422 116.221 108.331C111.069 117.745 107.701 127.907 106.211 138.535L106.1 139.33L105.299 139.393C96.3408 140.094 87.7219 142.453 79.6818 146.403C71.8694 150.242 64.8932 155.434 58.947 161.834C46.7289 174.987 40 192.123 40 210.087C40 219.66 41.8748 228.947 45.5723 237.689C47.3495 241.891 49.5491 245.943 52.1102 249.734C54.6472 253.489 57.5607 257.02 60.7699 260.23C63.9791 263.439 67.5104 266.352 71.2655 268.889C75.0565 271.45 79.109 273.65 83.3106 275.427C92.0525 279.125 101.339 281 110.913 281H302.345H314.437ZM314.437 282H302.345H110.913C71.1966 282 39 249.803 39 210.087C39 172.286 68.1646 141.297 105.221 138.396C111.35 94.6574 148.917 61 194.345 61C228.048 61 257.422 79.5231 272.845 106.946C282.087 103.741 292.012 102 302.345 102C352.051 102 392.345 142.294 392.345 191.999C392.345 206.459 388.936 220.121 382.877 232.227C373.54 261.109 346.427 282 314.437 282Z"
                    fill="url(#paint1_linear_135_51)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_135_51"
                    x="77.9736"
                    y="0.0932617"
                    width="138.879"
                    height="135.194"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur_135_51" />
                </filter>
                <filter
                    id="filter1_d_135_51"
                    x={19}
                    y={33}
                    width="413.346"
                    height={281}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={10} dy={2} />
                    <feGaussianBlur stdDeviation={15} />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.302 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_135_51"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_135_51"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_135_51"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(322.568 162.109) rotate(43.4125) scale(147.118 159.643)"
                >
                    <stop stopColor="white" stopOpacity="0.604" />
                    <stop offset="0.0001" stopColor="white" stopOpacity="0.95" />
                    <stop offset={1} stopColor="white" stopOpacity="0.95" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_135_51"
                    x1="110.852"
                    y1="108.06"
                    x2="349.352"
                    y2="282.06"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.1" />
                    <stop offset={1} stopColor="white" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ShowersNightIcon;

/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const HumidityIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            enableBackground="new 0 0 512 512"
            fill="var(--c-white)"
            style={{ height: "20px" }}
        >
            <g>
                <g>
                    <path d="m270,16.6c-7.9-7.5-20.2-7.5-28.1,0-7.2,6.9-177.7,169.9-177.7,279.4-1.42109e-14,113 86,205 191.8,205 105.7,0 191.8-92 191.8-205 0-109.5-170.5-272.5-177.8-279.4zm-14,443.6c-83.2,0-150.9-73.7-150.9-164.2 0-69.5 98.6-183.2 150.9-236 52.3,52.8 150.9,166.5 150.9,236 0,90.5-67.7,164.2-150.9,164.2z" />
                    <path d="m178.2,291.7c0.2-11.2-8.6-20.5-19.9-20.8-0.2,0-0.4,0-0.5,0-11,0-20.1,8.8-20.4,19.9-0.1,2.3-1.4,57.8 42.5,103.8 9.8,10.3 24.9,4.6 28.9,0.9 8.2-7.7 8.6-20.6 0.9-28.9-31.6-33.5-31.5-74-31.5-74.9z" />
                </g>
            </g>
        </svg>
    );
};

export default HumidityIcon;

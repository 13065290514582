/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const LightRainNightIcon = () => {
    return (
        <svg
            width={420}
            height={126}
            className="w-100"
            viewBox="0 0 420 347"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M104.819 293.001C106.419 293.019 122.637 318.342 122.637 328.183C122.637 332.909 120.76 337.441 117.418 340.782C114.077 344.124 109.545 346.001 104.819 346.001C100.093 346.001 95.5613 344.124 92.2197 340.782C88.8782 337.441 87.001 332.909 87.001 328.183C87.001 318.343 103.22 292.983 104.819 293.001Z"
                fill="#42A0F0"
            />
            <path
                d="M91.2341 334.843C93.0145 335.387 95.2772 333.148 96.2881 329.842C97.2989 326.536 96.675 323.414 94.8946 322.87C93.1142 322.326 90.8515 324.565 89.8407 327.871C88.8298 331.177 89.4537 334.298 91.2341 334.843Z"
                fill="white"
            />
            <path
                d="M197.819 293.001C199.419 293.019 215.637 318.342 215.637 328.183C215.637 332.909 213.76 337.441 210.418 340.782C207.077 344.124 202.545 346.001 197.819 346.001C193.093 346.001 188.561 344.124 185.22 340.782C181.878 337.441 180.001 332.909 180.001 328.183C180.001 318.343 196.22 292.983 197.819 293.001Z"
                fill="#42A0F0"
            />
            <path
                d="M184.234 334.843C186.015 335.387 188.277 333.148 189.288 329.842C190.299 326.536 189.675 323.414 187.895 322.87C186.114 322.326 183.851 324.565 182.841 327.871C181.83 331.177 182.454 334.298 184.234 334.843Z"
                fill="white"
            />
            <path
                d="M290.819 293.001C292.419 293.019 308.637 318.342 308.637 328.183C308.637 332.909 306.76 337.441 303.418 340.782C300.077 344.124 295.545 346.001 290.819 346.001C286.093 346.001 281.561 344.124 278.22 340.782C274.878 337.441 273.001 332.909 273.001 328.183C273.001 318.343 289.22 292.983 290.819 293.001Z"
                fill="#42A0F0"
            />
            <path
                d="M277.234 334.843C279.015 335.387 281.277 333.148 282.288 329.842C283.299 326.536 282.675 323.414 280.895 322.87C279.114 322.326 276.851 324.565 275.841 327.871C274.83 331.177 275.454 334.298 277.234 334.843Z"
                fill="white"
            />
            <path
                d="M151.819 215.001C153.419 215.019 169.637 240.342 169.637 250.183C169.637 254.909 167.76 259.441 164.418 262.782C161.077 266.124 156.545 268.001 151.819 268.001C147.093 268.001 142.561 266.124 139.22 262.782C135.878 259.441 134.001 254.909 134.001 250.183C134.001 240.343 150.22 214.983 151.819 215.001Z"
                fill="#42A0F0"
            />
            <path
                d="M138.234 256.843C140.015 257.387 142.277 255.148 143.288 251.842C144.299 248.536 143.675 245.414 141.895 244.87C140.114 244.326 137.851 246.565 136.841 249.871C135.83 253.177 136.454 256.298 138.234 256.843Z"
                fill="white"
            />
            <path
                d="M249.819 215.001C251.419 215.019 267.637 240.342 267.637 250.183C267.637 254.909 265.76 259.441 262.418 262.782C259.077 266.124 254.545 268.001 249.819 268.001C245.093 268.001 240.561 266.124 237.22 262.782C233.878 259.441 232.001 254.909 232.001 250.183C232.001 240.343 248.22 214.983 249.819 215.001Z"
                fill="#42A0F0"
            />
            <path
                d="M236.234 256.843C238.015 257.387 240.277 255.148 241.288 251.842C242.299 248.536 241.675 245.414 239.895 244.87C238.114 244.326 235.851 246.565 234.841 249.871C233.83 253.177 234.454 256.298 236.234 256.843Z"
                fill="white"
            />
            <path
                d="M103.268 6.72311C110.278 6.72135 117.27 7.42963 124.137 8.83711C137.39 11.5668 149.974 16.8888 161.164 24.4971C176.667 35.0048 189.053 49.4945 197.021 66.4436C204.989 83.3926 208.244 102.175 206.444 120.817C204.644 139.458 197.856 157.271 186.793 172.383C175.73 187.495 160.801 199.347 143.574 206.695C137.312 209.356 130.8 211.388 124.136 212.76C109.685 215.69 94.7754 215.508 80.4005 212.224C66.0257 208.941 52.5155 202.632 40.77 193.718C29.0244 184.804 19.3132 173.489 12.2832 160.528C5.25319 147.566 1.06578 133.256 -7.62939e-05 118.549C11.3314 121.201 23.1023 121.372 34.5059 119.049C44.7389 116.953 54.4592 112.863 63.1119 107.012C75.9742 98.3288 86.0422 86.1017 92.0959 71.8121C94.1414 66.9726 95.7027 61.9424 96.7569 56.7951C99.004 45.7664 98.9209 34.3898 96.5129 23.3951C95.3016 17.8974 93.5102 12.5437 91.1689 7.42412C95.185 6.95677 99.2248 6.7227 103.268 6.72311V6.72311Z"
                fill="#713FFD"
            />
            <g filter="url(#filter0_f_131_516)">
                <path
                    d="M173.339 38.8961C150.095 17.9666 119.644 13.8914 105.325 29.7938C91.0067 45.6962 98.2426 75.5544 121.487 96.4839C144.732 117.413 175.183 121.489 189.501 105.586C203.82 89.6838 196.584 59.8256 173.339 38.8961Z"
                    fill="#A586FC"
                />
            </g>
            <g filter="url(#filter1_d_131_516)">
                <path
                    d="M289.993 282H98.5615C79.9911 281.986 62.146 274.789 48.7621 261.915C35.3782 249.041 27.4932 231.489 26.7577 212.933C26.0221 194.377 32.4932 176.256 44.8161 162.364C57.1391 148.471 74.3584 139.884 92.8695 138.4C95.5093 119.76 103.927 102.417 116.937 88.8099C129.948 75.2032 146.897 66.0179 165.4 62.5463C183.903 59.0747 203.029 61.4915 220.087 69.4567C237.145 77.4218 251.277 90.5345 260.493 106.95C277.399 101.098 295.664 100.414 312.959 104.986C330.255 109.558 345.796 119.178 357.602 132.619C369.407 146.06 376.941 162.713 379.243 180.453C381.545 198.194 378.51 216.218 370.526 232.227C365.84 246.69 356.693 259.298 344.397 268.24C332.101 277.182 317.289 281.999 302.086 282H289.993Z"
                    fill="url(#paint0_radial_131_516)"
                    fillOpacity="0.8"
                />
                <path
                    d="M302.085 281C309.805 281 317.395 279.767 324.644 277.336C331.654 274.985 338.236 271.555 344.206 267.14C350.107 262.776 355.28 257.548 359.581 251.602C363.936 245.582 367.298 238.96 369.574 231.919L369.597 231.847L369.631 231.779C372.658 225.731 374.989 219.333 376.559 212.763C378.175 206.003 378.994 199.017 378.994 191.999C378.994 185.96 378.385 179.925 377.186 174.062C376.016 168.348 374.272 162.728 372 157.357C369.77 152.084 367.009 146.997 363.795 142.24C360.611 137.527 356.954 133.095 352.926 129.067C348.898 125.039 344.467 121.383 339.754 118.199C334.996 114.984 329.91 112.224 324.636 109.993C319.265 107.722 313.645 105.977 307.931 104.808C302.068 103.608 296.033 103 289.994 103C279.994 103 270.179 104.645 260.821 107.891L260.031 108.165L259.621 107.436C252.006 93.8942 240.928 82.5216 227.585 74.5478C220.838 70.5152 213.589 67.399 206.039 65.2857C198.251 63.1055 190.161 62 181.994 62C171.009 62 160.286 63.977 150.123 67.876C140.305 71.6427 131.299 77.0837 123.354 84.048C115.454 90.9722 108.899 99.1422 103.87 108.331C98.7169 117.745 95.349 127.907 93.8597 138.535L93.7483 139.33L92.9474 139.393C83.9892 140.094 75.3703 142.453 67.3302 146.403C59.5178 150.242 52.5416 155.434 46.5954 161.834C34.3773 174.987 27.6484 192.123 27.6484 210.087C27.6484 219.66 29.5232 228.947 33.2207 237.689C34.9979 241.891 37.1976 245.943 39.7587 249.734C42.2956 253.489 45.2092 257.02 48.4183 260.23C51.6275 263.439 55.1588 266.352 58.9139 268.889C62.7049 271.45 66.7574 273.65 70.9591 275.427C79.7009 279.125 88.9876 281 98.5611 281H289.994H302.085ZM302.085 282H289.994H98.5611C58.845 282 26.6484 249.803 26.6484 210.087C26.6484 172.286 55.8131 141.297 92.8694 138.396C98.9988 94.6574 136.565 61 181.994 61C215.696 61 245.071 79.5231 260.493 106.946C269.735 103.741 279.661 102 289.994 102C339.699 102 379.994 142.294 379.994 191.999C379.994 206.459 376.585 220.121 370.525 232.227C361.188 261.109 334.076 282 302.085 282Z"
                    fill="url(#paint1_linear_131_516)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_131_516"
                    x="77.9736"
                    y="0.0932617"
                    width="138.879"
                    height="135.194"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur_131_516" />
                </filter>
                <filter
                    id="filter1_d_131_516"
                    x="6.64844"
                    y={33}
                    width="413.346"
                    height={281}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={10} dy={2} />
                    <feGaussianBlur stdDeviation={15} />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.302 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_131_516"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_131_516"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_131_516"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(310.217 162.109) rotate(43.4125) scale(147.118 159.643)"
                >
                    <stop stopColor="white" stopOpacity="0.604" />
                    <stop offset="0.0001" stopColor="white" stopOpacity="0.95" />
                    <stop offset={1} stopColor="white" stopOpacity="0.95" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_131_516"
                    x1="98.5"
                    y1="108.06"
                    x2={337}
                    y2="282.06"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.1" />
                    <stop offset={1} stopColor="white" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default LightRainNightIcon;

/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const FreezingRainIcon = () => {
    return (
        <svg
            width={428}
            height={126}
            viewBox="0 0 428 331"
            className="w-100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M153.613 167.465C157.526 167.509 197.226 229.494 197.226 253.581C197.226 265.148 192.631 276.241 184.452 284.42C176.273 292.599 165.18 297.194 153.613 297.194C142.046 297.194 130.953 292.599 122.774 284.42C114.595 276.241 110 265.148 110 253.581C110 229.494 149.7 167.422 153.613 167.465Z"
                fill="#42A0F0"
            />
            <path
                d="M120.36 269.884C124.717 271.217 130.256 265.736 132.73 257.642C135.205 249.549 133.678 241.908 129.32 240.575C124.962 239.243 119.424 244.724 116.949 252.817C114.475 260.911 116.002 268.552 120.36 269.884Z"
                fill="white"
            />
            <path
                d="M256.359 249.207C258.824 249.236 283.839 288.291 283.839 303.468C283.795 310.728 280.881 317.675 275.732 322.793C270.583 327.911 263.618 330.783 256.358 330.783C249.099 330.783 242.134 327.911 236.985 322.793C231.836 317.675 228.922 310.728 228.878 303.468C228.878 288.291 253.893 249.18 256.359 249.207Z"
                fill="#42A0F0"
            />
            <path
                d="M235.406 313.74C238.152 314.58 241.642 311.127 243.201 306.027C244.76 300.928 243.798 296.114 241.052 295.274C238.306 294.435 234.816 297.888 233.257 302.987C231.698 308.087 232.66 312.901 235.406 313.74Z"
                fill="white"
            />
            <g filter="url(#filter0_f_115_138)">
                <path
                    d="M364.239 60V178.484"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M364.003 88.9629L384.409 67.8989"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M364.002 88.9629L342.938 67.8989"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M364.003 152.154L384.409 173.218"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M364.002 152.154L342.938 173.218"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M415.261 90.1113L312.651 149.353"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M390.06 104.387L418.505 111.528"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M390.06 104.387L397.77 75.6135"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M335.335 135.983L327.296 164.187"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M335.335 135.983L306.561 128.273"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M414.694 149.353L312.084 90.1113"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M389.73 134.666L397.769 162.871"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M389.73 134.667L418.504 126.957"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M335.005 103.071L306.56 110.211"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <path
                    d="M335.005 103.071L327.295 74.2971"
                    stroke="#42A0F0"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
            </g>
            <g filter="url(#filter1_d_115_138)">
                <path
                    d="M283.345 249H91.913C73.3426 248.986 55.4976 241.789 42.1137 228.915C28.7298 216.041 20.8447 198.489 20.1092 179.933C19.3737 161.377 25.8448 143.256 38.1677 129.364C50.4906 115.471 67.71 106.884 86.221 105.4C88.8608 86.7599 97.2781 69.4166 110.289 55.8099C123.299 42.2032 140.248 33.0179 158.752 29.5463C177.255 26.0747 196.381 28.4915 213.439 36.4567C230.497 44.4218 244.628 57.5345 253.845 73.9499C270.75 68.098 289.015 67.4142 306.311 71.9859C323.606 76.5576 339.148 86.1775 350.953 99.6186C362.759 113.06 370.293 129.713 372.594 147.453C374.896 165.194 371.861 183.218 363.877 199.227C359.192 213.69 350.045 226.298 337.749 235.24C325.453 244.182 310.641 248.999 295.437 249H283.345Z"
                    fill="url(#paint0_radial_115_138)"
                    fillOpacity="0.8"
                />
                <path
                    d="M295.437 248C303.156 248 310.746 246.767 317.996 244.336C325.006 241.985 331.587 238.555 337.557 234.14C343.458 229.776 348.631 224.548 352.932 218.602C357.287 212.582 360.649 205.96 362.925 198.919L362.949 198.847L362.983 198.779C366.01 192.731 368.341 186.333 369.911 179.763C371.526 173.003 372.345 166.017 372.345 158.999C372.345 152.96 371.737 146.925 370.537 141.062C369.368 135.348 367.623 129.728 365.352 124.357C363.121 119.084 360.36 113.997 357.146 109.24C353.962 104.527 350.305 100.095 346.278 96.0672C342.25 92.0395 337.818 88.3828 333.105 85.1988C328.347 81.9845 323.261 79.2237 317.988 76.9932C312.617 74.7216 306.997 72.9769 301.283 71.8077C295.42 70.6079 289.384 69.9995 283.345 69.9995C273.346 69.9995 263.531 71.6451 254.172 74.8907L253.383 75.1645L252.973 74.4361C245.357 60.8942 234.279 49.5216 220.937 41.5478C214.189 37.5152 206.94 34.399 199.391 32.2857C191.602 30.1055 183.512 29 175.345 29C164.361 29 153.638 30.977 143.475 34.876C133.657 38.6427 124.65 44.0837 116.705 51.048C108.806 57.9722 102.251 66.1422 97.2213 75.3308C92.0685 84.7451 88.7006 94.9073 87.2113 105.535L87.0998 106.33L86.299 106.393C77.3408 107.094 68.7219 109.453 60.6818 113.403C52.8694 117.242 45.8932 122.434 39.947 128.834C27.7289 141.987 21 159.123 21 177.087C21 186.66 22.8748 195.947 26.5723 204.689C28.3495 208.891 30.5491 212.943 33.1102 216.734C35.6472 220.489 38.5607 224.02 41.7699 227.23C44.9791 230.439 48.5104 233.352 52.2655 235.889C56.0565 238.45 60.109 240.65 64.3106 242.427C73.0525 246.125 82.3391 248 91.9127 248H283.345H295.437ZM295.437 249H283.345H91.9127C52.1966 249 20 216.803 20 177.087C20 139.286 49.1646 108.297 86.221 105.396C92.3504 61.6574 129.917 28 175.345 28C209.048 28 238.422 46.5231 253.845 73.9459C263.087 70.7406 273.012 68.9995 283.345 68.9995C333.051 68.9995 373.345 109.294 373.345 158.999C373.345 173.459 369.936 187.121 363.877 199.227C354.54 228.109 327.427 249 295.437 249Z"
                    fill="url(#paint1_linear_115_138)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_115_138"
                    x="297.559"
                    y={51}
                    width="129.947"
                    height="136.484"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation={2} result="effect1_foregroundBlur_115_138" />
                </filter>
                <filter
                    id="filter1_d_115_138"
                    x={0}
                    y={0}
                    width="413.346"
                    height={281}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={10} dy={2} />
                    <feGaussianBlur stdDeviation={15} />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.302 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_115_138"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_115_138"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_115_138"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(303.568 129.109) rotate(43.4125) scale(147.118 159.643)"
                >
                    <stop stopColor="white" stopOpacity="0.604" />
                    <stop offset="0.0001" stopColor="white" stopOpacity="0.95" />
                    <stop offset={1} stopColor="white" stopOpacity="0.95" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_115_138"
                    x1="91.8516"
                    y1="75.0601"
                    x2="330.352"
                    y2="249.06"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.1" />
                    <stop offset={1} stopColor="white" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FreezingRainIcon;

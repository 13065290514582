/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const HazeIcon = () => {
    return (
        <svg
            width={289}
            height={126}
            viewBox="0 0 289 207"
            fill="none"
            className="w-100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M147.908 60.9313C147.908 60.9313 138.144 74.1673 130.513 73.7313C120.947 73.1693 119.313 61.6313 110.445 60.9313C101.167 61.0703 100.22 73.0313 90.1639 73.7313C81.1539 73.5703 77.3369 61.1493 68.7479 60.9313C60.1589 60.7133 51.0039 73.7313 51.0039 73.7313"
                stroke="white"
                strokeWidth={16}
                strokeLinecap="round"
            />
            <path
                d="M153.919 134.931C153.919 134.931 139.219 148.167 127.726 147.731C113.326 147.169 110.855 135.631 97.508 134.931C83.538 135.07 82.108 147.031 66.969 147.731C53.403 147.568 47.655 135.148 34.719 134.931C21.783 134.714 8 147.731 8 147.731"
                stroke="white"
                strokeWidth={16}
                strokeLinecap="round"
            />
            <path
                d="M237.919 185.931C237.919 185.931 223.219 199.167 211.726 198.731C197.326 198.169 194.855 186.631 181.508 185.931C167.538 186.07 166.108 198.031 150.969 198.731C137.403 198.568 131.655 186.148 118.719 185.931C105.783 185.714 92 198.731 92 198.731"
                stroke="white"
                strokeWidth={16}
                strokeLinecap="round"
            />
            <path
                d="M228.186 140.929C261.76 140.929 288.978 113.573 288.978 79.8283C288.978 46.0831 261.76 18.7273 228.186 18.7273C194.611 18.7273 167.394 46.0831 167.394 79.8283C167.394 113.573 194.611 140.929 228.186 140.929Z"
                fill="#FA9E42"
            />
            <g filter="url(#filter0_f_128_233)">
                <path
                    d="M223.792 71.1396C243.996 60.4083 255.296 42.1482 249.032 30.3545C242.768 18.5607 221.311 17.6994 201.107 28.4307C180.903 39.1619 169.603 57.4221 175.867 69.2158C182.131 81.0096 203.588 81.8709 223.792 71.1396Z"
                    fill="#F8E36F"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_128_233"
                    x="154.139"
                    y="0.913452"
                    width="116.622"
                    height="97.7434"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur_128_233" />
                </filter>
            </defs>
        </svg>
    );
};

export default HazeIcon;

/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const SearchIcon = () => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-ejfnb48ut:10406" fill="white">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 12.8492 18.3726 14.5519 17.319 15.9067C17.332 15.9184 17.3447 15.9304 17.3571 15.9429L17.3573 15.943L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L15.9429 17.3571C15.9304 17.3447 15.9184 17.332 15.9067 17.319C14.5519 18.3726 12.8492 19 11 19ZM11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z"
            />
        </mask>
        <path
            d="M17.319 15.9067L15.7402 14.679L14.5987 16.1469L15.9798 17.3921L17.319 15.9067ZM17.3573 15.943L15.943 17.3573V17.3573L17.3573 15.943ZM20.7071 19.2929L22.1213 17.8787L22.1213 17.8787L20.7071 19.2929ZM20.7071 20.7071L22.1213 22.1213L22.1213 22.1213L20.7071 20.7071ZM19.2929 20.7071L17.8787 22.1213L17.8787 22.1213L19.2929 20.7071ZM15.9429 17.3571L17.3571 15.9429H17.3571L15.9429 17.3571ZM15.9067 17.319L17.3921 15.9798L16.1469 14.5987L14.679 15.7402L15.9067 17.319ZM1 11C1 16.5228 5.47715 21 11 21V17C7.68629 17 5 14.3137 5 11H1ZM11 1C5.47715 1 1 5.47715 1 11H5C5 7.68629 7.68629 5 11 5V1ZM21 11C21 5.47715 16.5228 1 11 1V5C14.3137 5 17 7.68629 17 11H21ZM18.8978 17.1345C20.2147 15.441 21 13.3094 21 11H17C17 12.389 16.5305 13.6627 15.7402 14.679L18.8978 17.1345ZM15.9798 17.3921C15.9673 17.3808 15.955 17.3692 15.9429 17.3571L18.7713 14.5287C18.7343 14.4917 18.6966 14.4559 18.6583 14.4213L15.9798 17.3921ZM15.9429 17.3571L15.943 17.3573L18.7715 14.5288L18.7713 14.5287L15.9429 17.3571ZM15.943 17.3573L19.2929 20.7071L22.1213 17.8787L18.7715 14.5288L15.943 17.3573ZM19.2929 20.7071C18.9024 20.3166 18.9024 19.6834 19.2929 19.2929L22.1213 22.1213C23.2929 20.9497 23.2929 19.0503 22.1213 17.8787L19.2929 20.7071ZM19.2929 19.2929C19.6834 18.9024 20.3166 18.9024 20.7071 19.2929L17.8787 22.1213C19.0503 23.2929 20.9497 23.2929 22.1213 22.1213L19.2929 19.2929ZM20.7071 19.2929L17.3571 15.9429L14.5287 18.7713L17.8787 22.1213L20.7071 19.2929ZM17.3571 15.9429C17.3692 15.955 17.3808 15.9673 17.3921 15.9798L14.4213 18.6583C14.4559 18.6966 14.4917 18.7343 14.5287 18.7713L17.3571 15.9429ZM11 21C13.3094 21 15.441 20.2147 17.1345 18.8978L14.679 15.7402C13.6627 16.5305 12.389 17 11 17V21ZM15 11C15 13.2091 13.2091 15 11 15V19C15.4183 19 19 15.4183 19 11H15ZM11 7C13.2091 7 15 8.79086 15 11H19C19 6.58172 15.4183 3 11 3V7ZM7 11C7 8.79086 8.79086 7 11 7V3C6.58172 3 3 6.58172 3 11H7ZM11 15C8.79086 15 7 13.2091 7 11H3C3 15.4183 6.58172 19 11 19V15Z"
            fill="#5D6C76"
            mask="url(#path-1-inside-ejfnb48ut:10406)"
        />
    </svg>
);

export default SearchIcon;

/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";

const ThunderStormIcon = () => {
    return (
        <svg
            width={432}
            height={126}
            className="w-100"
            viewBox="0 0 432 374"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M197.333 201.763H265.405L226.272 259.229L272.027 260.095L175.99 373.763L199.26 287.1H156.66L197.333 201.763Z"
                fill="url(#paint0_linear_114_31)"
            />
            <path
                d="M302.518 262.246H332.405L315.224 287.477L335.313 287.857L293.147 337.763L303.364 299.714H284.664L302.518 262.246Z"
                fill="url(#paint1_linear_114_31)"
            />
            <path
                d="M95.5181 287.763H125.405L108.224 312.994L128.313 313.374L86.1471 363.28L96.3641 325.231H77.6641L95.5181 287.763Z"
                fill="url(#paint2_linear_114_31)"
            />
            <path
                d="M108.148 214.763C162.548 214.763 206.648 170.439 206.648 115.763C206.648 61.0868 162.548 16.763 108.148 16.763C53.7484 16.763 9.64844 61.0868 9.64844 115.763C9.64844 170.439 53.7484 214.763 108.148 214.763Z"
                fill="#FA9E42"
            />
            <g filter="url(#filter0_f_114_31)">
                <path
                    d="M101.03 101.684C133.767 84.2964 152.077 54.7096 141.928 35.6004C131.778 16.4911 97.0115 15.0957 64.2749 32.4835C31.5383 49.8714 13.228 79.4581 23.3778 98.5674C33.5275 117.677 68.2938 119.072 101.03 101.684Z"
                    fill="#F8E36F"
                />
            </g>
            <g filter="url(#filter1_d_114_31)">
                <path
                    d="M301.345 275.76H109.913C91.3426 275.746 73.4976 268.549 60.1137 255.675C46.7298 242.801 38.8447 225.249 38.1092 206.693C37.3737 188.137 43.8448 170.016 56.1677 156.124C68.4906 142.231 85.71 133.644 104.221 132.16C106.861 113.52 115.278 96.1766 128.289 82.5699C141.299 68.9632 158.248 59.7779 176.752 56.3063C195.255 52.8347 214.381 55.2515 231.439 63.2167C248.497 71.1818 262.628 84.2945 271.845 100.71C288.75 94.858 307.015 94.1742 324.311 98.7459C341.606 103.318 357.148 112.938 368.953 126.379C380.759 139.82 388.293 156.473 390.594 174.213C392.896 191.954 389.861 209.978 381.877 225.987C377.192 240.45 368.045 253.058 355.749 262C343.453 270.942 328.641 275.759 313.437 275.76H301.345Z"
                    fill="url(#paint3_radial_114_31)"
                    fillOpacity="0.8"
                />
                <path
                    d="M313.437 274.76C321.156 274.76 328.746 273.527 335.996 271.096C343.006 268.745 349.587 265.315 355.557 260.9C361.458 256.536 366.631 251.308 370.932 245.362C375.287 239.342 378.649 232.72 380.925 225.679L380.949 225.607L380.983 225.539C384.01 219.491 386.341 213.093 387.911 206.523C389.526 199.763 390.345 192.777 390.345 185.76C390.345 179.72 389.737 173.685 388.537 167.822C387.368 162.108 385.623 156.488 383.352 151.117C381.121 145.844 378.36 140.758 375.146 136C371.962 131.287 368.305 126.855 364.278 122.827C360.25 118.799 355.818 115.143 351.105 111.959C346.347 108.744 341.261 105.984 335.988 103.753C330.617 101.482 324.997 99.7369 319.283 98.5677C313.42 97.3679 307.384 96.7595 301.345 96.7595C291.346 96.7595 281.531 98.4051 272.172 101.651L271.383 101.925L270.973 101.196C263.357 87.6542 252.279 76.2816 238.937 68.3078C232.189 64.2752 224.94 61.159 217.391 59.0457C209.602 56.8655 201.512 55.76 193.345 55.76C182.361 55.76 171.638 57.737 161.475 61.6361C151.657 65.4027 142.65 70.8437 134.705 77.808C126.806 84.7322 120.251 92.9022 115.221 102.091C110.069 111.505 106.701 121.667 105.211 132.295L105.1 133.09L104.299 133.153C95.3408 133.854 86.7219 136.213 78.6818 140.163C70.8694 144.002 63.8932 149.194 57.947 155.594C45.7289 168.747 39 185.883 39 203.847C39 213.42 40.8748 222.707 44.5723 231.449C46.3495 235.651 48.5491 239.703 51.1102 243.494C53.6472 247.249 56.5607 250.78 59.7699 253.99C62.9791 257.199 66.5104 260.112 70.2655 262.649C74.0565 265.21 78.109 267.41 82.3106 269.187C91.0525 272.885 100.339 274.76 109.913 274.76H301.345H313.437ZM313.437 275.76H301.345H109.913C70.1966 275.76 38 243.563 38 203.847C38 166.046 67.1646 135.057 104.221 132.156C110.35 88.4174 147.917 54.76 193.345 54.76C227.048 54.76 256.422 73.2831 271.845 100.706C281.087 97.5006 291.012 95.7595 301.345 95.7595C351.051 95.7595 391.345 136.054 391.345 185.76C391.345 200.219 387.936 213.881 381.877 225.987C372.54 254.869 345.427 275.76 313.437 275.76Z"
                    fill="url(#paint4_linear_114_31)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_114_31"
                    x="0.578125"
                    y="0.303284"
                    width="164.149"
                    height="133.561"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur_114_31" />
                </filter>
                <filter
                    id="filter1_d_114_31"
                    x={18}
                    y="26.76"
                    width="413.346"
                    height={281}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx={10} dy={2} />
                    <feGaussianBlur stdDeviation={15} />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0 0.501961 0 0 0 0.302 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_114_31"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_114_31"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_114_31"
                    x1="203.73"
                    y1="287.763"
                    x2="149.841"
                    y2="268.007"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF0AA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_114_31"
                    x1="305.329"
                    y1="300.005"
                    x2="281.67"
                    y2="291.332"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF0AA" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_114_31"
                    x1="98.3289"
                    y1="325.522"
                    x2="74.67"
                    y2="316.849"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF0AA" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_114_31"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(321.568 155.869) rotate(43.4125) scale(147.118 159.643)"
                >
                    <stop stopColor="white" stopOpacity="0.604" />
                    <stop offset="0.0001" stopColor="white" stopOpacity="0.95" />
                    <stop offset={1} stopColor="white" stopOpacity="0.95" />
                </radialGradient>
                <linearGradient
                    id="paint4_linear_114_31"
                    x1="109.852"
                    y1="101.82"
                    x2="348.352"
                    y2="275.82"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.1" />
                    <stop offset={1} stopColor="white" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ThunderStormIcon;
